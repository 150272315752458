.dot-loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20px;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #09f;
  animation: dot-bounce 1.2s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes dot-bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}
