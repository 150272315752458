.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.25) !important;
  z-index: 10;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  background-color: white;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 10px !important;
}
