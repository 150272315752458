#root {
  @apply flex flex-col min-h-screen;
}

.content-container {
  @apply flex flex-col flex-1 justify-center items-center;
}

.dashed-container {
  border: 2px solid #d0d5dd;
  border-image: repeating-linear-gradient(
      131deg,
      transparent,
      transparent 10px,
      #d0d5dd 10px,
      #d0d5dd 20px
    )
    1;
  border-image-slice: 1;
}

body {
  font-family: 'Sarabun', sans-serif;
  letter-spacing: 0.2px;
}

.loader {
  width: 120px;
  height: 22px;
  border-radius: 20px;
  color: #176852;
  border: 2px solid;
  position: relative;
}
.loader::before {
  content: '';
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 2s infinite;
}
@keyframes l6 {
  100% {
    inset: 0;
  }
}
